/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UserKeycloakDtoNBK {
  id?: string;
  createdTimestamp?: number;
  username?: string;
  enabled?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  companyId?: string;
  companyName?: string;
  country?: string;
  province?: string;
  city?: string;
  address?: string;
  addressNumber?: string;
  postalCode?: string;
  ovenBrand?: string;
  ovenType?: string;
  ovenYear?: string;
  ovenCapacity?: string;
  sector?: string;
  mealsPerDay?: string;
  position?: string;
  locale?: string;
  completed?: boolean;
  temperatureUnit?: string;
  measureUnit?: string;
  timerInSeconds?: boolean;
  nabookIsMaster?: boolean;
  multiDisplay?: boolean;
  settingsPin?: string;
  agreePrivacy?: boolean;
  roles?: Array<UserKeycloakDtoNBK.RolesEnum>;
}
export namespace UserKeycloakDtoNBK {
  export type RolesEnum =
    | 'DEFAULT_ROLE'
    | 'CHEF_EXECUTIVE'
    | 'CHEF_LAINOX'
    | 'USER_MANAGER'
    | 'SUPERUSER'
    | 'SERVICE'
    | 'SERVICE_ADMIN';
  export const RolesEnum = {
    DefaultRole: 'DEFAULT_ROLE' as RolesEnum,
    ChefExecutive: 'CHEF_EXECUTIVE' as RolesEnum,
    ChefLainox: 'CHEF_LAINOX' as RolesEnum,
    UserManager: 'USER_MANAGER' as RolesEnum,
    Superuser: 'SUPERUSER' as RolesEnum,
    Service: 'SERVICE' as RolesEnum,
    ServiceAdmin: 'SERVICE_ADMIN' as RolesEnum
  };
}
