import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DeviceParamNBK, DisplayBoDtoNBK, DisplayListBoDtoNBK, PhaseBoDtoNBK, RecipeBoDtoNBK, UserKeycloakDtoNBK } from '../api/nbk';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from 'src/environments/environment';
import { DefaultRecipeFilterEnum } from '../shared/recipes/recipes.component';
import { Config, Layout } from 'plotly.js';
import { TranslateService } from '@ngx-translate/core';


export type ScreenSize = 
  | "SMALL"
  | "MEDIUM"
  | "LARGE"
export const ScreenSize = {
  Small: 'SMALL' as ScreenSize,
  Medium: 'MEDIUM' as ScreenSize,
  Large: 'LARGE' as ScreenSize
};

export type PhaseBoDtoNBKComplete = PhaseBoDtoNBK & { group: number };

export type SectionTypeEnum =
  | 'TEMPERATURE'
  | 'WAVE_POWER'
  | 'TIME'
  | 'TIME_SONDA'
  | 'STEAM'
  | 'FAN';
export const SectionTypeEnum = {
  Temperature: 'TEMPERATURE' as SectionTypeEnum,
  Steam: 'STEAM' as SectionTypeEnum,
  WavePower: 'WAVE_POWER' as SectionTypeEnum,
  Time: 'TIME' as SectionTypeEnum,
  Time_Sonda: 'TIME_SONDA' as SectionTypeEnum,
  Fan: 'FAN' as SectionTypeEnum
};

export type DeviceParamNBKExtended = DeviceParamNBK & {
  min: number;
  max: number;
  name: string;
};

export type DefaulteFilterEnum = DefaultRecipeFilterEnum;

export interface DefaultFilter {
  filter?: DefaulteFilterEnum;
  value?: any;
}

export type CookingModeEnum = RecipeBoDtoNBK.CookingModeEnum | DisplayBoDtoNBK.CookingModeEnum;
export type DeviceModelEnum = RecipeBoDtoNBK.DeviceModelEnum | DisplayBoDtoNBK.DeviceModelEnum;

export type SelectableCookingMode = {
  id: CookingModeEnum;
  name: string;
};

export type SelectableDeviceModel = {
  id: RecipeBoDtoNBK.DeviceModelEnum;
  name: string;
};

export const telemetryRange = [
  {
    value: '10s',
    description: _('TELEMETRY.RANGE.SECOND')
  },
  {
    value: '1m',
    description: _('TELEMETRY.RANGE.MINUTE')
  },
  {
    value: '1h',
    description: _('TELEMETRY.RANGE.HOUR')
  },
  {
    value: '1d',
    description: _('TELEMETRY.RANGE.DAY')
  },
  {
    value: '7d',
    description: _('TELEMETRY.RANGE.WEEK')
  },
  {
    value: '30d',
    description: _('TELEMETRY.RANGE.MONTH')
  }
];

export const telemetryType = [
  {
    value: 'all',
    description: _('TELEMETRY.TYPE.ALL')
  },
  {
    value: 'ext',
    description: _('TELEMETRY.TYPE.EXT')
  },
  {
    value: 'haccp',
    description: _('TELEMETRY.TYPE.HACCP')
  },
  {
    value: 'counters',
    description: _('TELEMETRY.TYPE.COUNTERS')
  }
];

export const deviceConfiguration = {
  NABOO: [
    {
      coockingMode: 'MULTILEVEL',
      tabList: [
        {
          ref: 'CONVECTION',
          img: {
            url: 'assets/images/btn_forno.png',
            alt: 'Convection'
          },
          label: 'Convection',
          sectionList: ['TEMPERATURE', 'TIME_SONDA', 'STEAM', 'FAN']
        },
        {
          ref: 'STEAM',
          img: {
            url: 'assets/images/btn_forno_vapore.png',
            alt: 'Steam'
          },
          label: 'Steam',
          sectionList: ['TEMPERATURE', 'TIME_SONDA', 'STEAM', 'FAN']
        },
        {
          ref: 'COMBO',
          img: {
            url: 'assets/images/btn_forno.png',
            alt: 'COMBO'
          },
          label: 'COMBO',
          sectionList: ['TEMPERATURE', 'TIME_SONDA', 'STEAM', 'FAN']
        }
      ]
    },
    {
      coockingMode: 'SINGLE_LEVEL',
      tabList: [
        {
          ref: 'CONVECTION',
          img: {
            url: 'assets/images/btn_forno.png',
            alt: 'Convection'
          },
          label: 'Convection',
          sectionList: ['TEMPERATURE', 'TIME_SONDA', 'STEAM', 'FAN']
        },
        {
          ref: 'STEAM',
          img: {
            url: 'assets/images/btn_forno_vapore.png',
            alt: 'Steam'
          },
          label: 'Steam',
          sectionList: ['TEMPERATURE', 'TIME_SONDA', 'STEAM', 'FAN']
        },
        {
          ref: 'COMBO',
          img: {
            url: 'assets/images/btn_forno.png',
            alt: 'Combo'
          },
          label: 'Combo',
          sectionList: ['TEMPERATURE', 'TIME_SONDA', 'STEAM', 'FAN']
        }
      ]
    }
  ],
  ORACLE: [
    {
      coockingMode: 'COMBI',
      tabList: [
        {
          ref: 'CONVECTION',
          img: {
            url: 'assets/images/btn_forno.png',
            alt: 'Convection'
          },
          label: 'Convection',
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'WAVE_POWER', disabled: true },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ]
        },
        {
          ref: 'MICROWAVE',
          img: {
            url: 'assets/images/btn_forno_vapore.png',
            alt: 'Microwave'
          },
          label: 'Microwave',
          sectionList: [
            { label: 'TEMPERATURE', disabled: true },
            { label: 'WAVE_POWER', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ]
        },
        {
          ref: 'COMBO',
          img: {
            url: 'assets/images/btn_forno_misto.png',
            alt: 'Combo'
          },
          label: 'Combo',
          sectionList: [
            { label: 'TEMPERATURE', disabled: false },
            { label: 'WAVE_POWER', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ]
        }
      ]
    },
    {
      coockingMode: 'HSO',
      tabList: [
        {
          ref: 'MICROWAVE',
          img: {
            url: 'assets/images/btn_forno_vapore.png',
            alt: 'Microwave'
          },
          label: 'Microwave',
          sectionList: [
            { label: 'WAVE_POWER', disabled: false },
            { label: 'TIME', disabled: false },
            { label: 'FAN', disabled: false }
          ]
        }
      ]
    }
  ]
};

export const classificationList = [
  { id: 1, name: 'PASTA' },
  { id: 2, name: 'VERDURE' },
  { id: 3, name: 'PESCE' },
  { id: 4, name: 'SMOKE GRILL' },
  { id: 5, name: 'CARNE' },
  { id: 6, name: 'COTTURE NOTTURNE' },
  { id: 7, name: 'SOTTOVUOTO VASOCOTTURA' },
  { id: 8, name: 'FINGER FOOD' },
  { id: 9, name: 'PASTICCERIA-PANE' },
  { id: 101, name: 'BISCOTTERIA' },
  { id: 102, name: 'PASTICCERIA SALATA' },
  { id: 103, name: 'DOLCI AL CUCCHIAIO' },
  { id: 104, name: 'LIEVITATI' },
  { id: 105, name: 'PANIFICAZIONE' },
  { id: 106, name: 'PASTICCERIA BASE' },
  { id: 107, name: 'TORTE DA FORNO' },
  { id: 108, name: 'PRODOTTI SURGELATI' }
];

export type FolderBoDtoNBK = {
  id: number;
  name: string;
  recipes: RecipeBoDtoNBK[];
};

export type PlotlyChart = {
  data: Array<any>,
  layout: Partial<Layout>,
  config: Partial<Config>
}

export const ingrTypeList = [
  { id: '', name: 'Tutti' },
  { id: '1', name: 'BEVANDE ALCOLICHE E ANALCOLICHE' },
  { id: '2', name: 'CARNI' },
  { id: '3', name: 'FORMAGGI, LATTICINI, UOVA' },
  { id: '4', name: 'MARMELLATE - CONFETTURE - ZUCCHERI - MIELE' },
  { id: '5', name: 'OLII - ACETI - GRASSI' },
  { id: '6', name: 'ORTOFRUTTA' },
  { id: '7', name: 'PASTA FRESCA E SECCA' },
  { id: '8', name: 'PASTICCERIA DOLCE' },
  { id: '9', name: 'PESCE' },
  { id: '10', name: 'PRODOTTI SURGELATI' },
  { id: '11', name: 'PRODOTTI VARI CUCINA E PASTICCERIA' },
  { id: '12', name: 'SALSE' },
  { id: '13', name: 'SALUMI' },
  { id: '14', name: 'SEMILAVORATI DI CUCINA' },
  { id: '15', name: 'SPEZIE, ERBE E AROMI' }
];
export const umList = [
  'kg',
  'g',
  'l',
  'ml',
  'lb',
  'oz',
  'cup',
  'Tblsp',
  'Tspn',
  'US Gal',
  'qt',
  'fl oz'
];

export const nationList = [
  { id: '1', name: _('COUNTRY_LIST.INTERNATIONAL') },
  { id: '2', name: _('COUNTRY_LIST.ITALY') },
  { id: '3', name: _('COUNTRY_LIST.AUSTRIA/GERMANY') },
  { id: '4', name: _('COUNTRY_LIST.RUSSIA') },
  { id: '5', name: _('COUNTRY_LIST.FRANCE') },
  { id: '6', name: _('COUNTRY_LIST.CHINA') },
  { id: '7', name: _('COUNTRY_LIST.INDONESIA') },
  { id: '8', name: _('COUNTRY_LIST.MALAYSIA') },
  { id: '9', name: _('COUNTRY_LIST.UK') },
  { id: '10', name: _('COUNTRY_LIST.POLAND') },
  { id: '11', name: _('COUNTRY_LIST.CZECH_REPUBLIC') },
  { id: '12', name: _('COUNTRY_LIST.CANADA') },
  { id: '13', name: _('COUNTRY_LIST.AUSTRALIA') },
  { id: '14', name: _('COUNTRY_LIST.SERBIA') },
  { id: '15', name: _('COUNTRY_LIST.BOSNIA') },
  { id: '16', name: _('COUNTRY_LIST.CROATIA') },
  { id: '17', name: _('COUNTRY_LIST.SPAIN') },
  { id: '18', name: _('COUNTRY_LIST.COLOMBIA') },
  { id: '19', name: _('COUNTRY_LIST.GREECE') },
  { id: '20', name: _('COUNTRY_LIST.PHILIPPINES') },
  { id: '21', name: _('COUNTRY_LIST.SOUTH_AFRICA') },
  { id: '22', name: _('COUNTRY_LIST.HOLLAND') },
  { id: '23', name: _('COUNTRY_LIST.SINGAPORE') },
  { id: '24', name: _('COUNTRY_LIST.PERU') },
  { id: '25', name: _('COUNTRY_LIST.MEXICO') },
  { id: '26', name: _('COUNTRY_LIST.CHILE') },
  { id: '27', name: _('COUNTRY_LIST.KOREA') }
];

export function fromMinutesToTimeString(minutes: string): string {
  let hours = 0;
  let minutesFinal = +minutes;
  while (minutesFinal >= 60) {
    hours++;
    minutesFinal = minutesFinal - 60;
  }
  return `${hours}h ${minutesFinal}'`;
}

export function fromSecondsToTimeString(seconds: string): string {
  let hours = 0;
  let minutes = 0;
  let secondsFinal = +seconds;
  while (secondsFinal >= 60) {
    minutes++;
    if (minutes === 60) {
      minutes = 0;
      hours++;
    }
    secondsFinal = secondsFinal - 60;
  }

  return `${hours}h ${minutes}' ${secondsFinal}''`;
}

export function markFormGroupTouched(formGroup: UntypedFormGroup) {
  Object.values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

export function checkFormValidity(form: UntypedFormGroup): boolean {
  if (form.invalid) {
    markFormGroupTouched(form);
    setTimeout(() => {
      let firstInvalidEl = (document.querySelector(
        'input.ng-invalid.ng-touched'
      ) ||
        document.querySelector(
          'ng-select.ng-invalid.ng-touched'
        )) as HTMLElement | null;

      if (firstInvalidEl) {
        firstInvalidEl.scrollIntoView({ block: 'center' });
        window.scrollBy(0, -100);
        firstInvalidEl.focus();
      }
    });
    return false;
  } else {
    return true;
  }
}

export function scrollPageTo(selector: string) {
  setTimeout(() => {
    let element = document.querySelector(
      selector
    ) as HTMLElement | null;

    if (element) {
      element.scrollIntoView({ block: 'center' });
      window.scrollBy(0, -100);
      element.focus();
    }
  });
}

export const provinceUserDetailList = [
  { id: '', value: '' },
  { id: 'AG', value: 'Agrigento' },
  { id: 'AL', value: 'Alessandria' },
  { id: 'AN', value: 'Ancona' },
  { id: 'AO', value: 'Aosta' },
  { id: 'AR', value: 'Arezzo' },
  { id: 'AP', value: 'Ascoli Piceno' },
  { id: 'AT', value: 'Asti' },
  { id: 'AV', value: 'Avellino' },
  { id: 'BA', value: 'Bari' },
  { id: 'BT', value: 'Barletta-Andria-Trani' },
  { id: 'BL', value: 'Belluno' },
  { id: 'BN', value: 'Benevento' },
  { id: 'BG', value: 'Bergamo' },
  { id: 'BI', value: 'Biella' },
  { id: 'BO', value: 'Bologna' },
  { id: 'BZ', value: 'Bolzano' },
  { id: 'BS', value: 'Brescia' },
  { id: 'BR', value: 'Brindisi' },
  { id: 'CA', value: 'Cagliari' },
  { id: 'CL', value: 'Caltanissetta' },
  { id: 'CB', value: 'Campobasso' },
  { id: 'CI', value: 'Carbonia-Iglesias' },
  { id: 'CE', value: 'Caserta' },
  { id: 'CT', value: 'Catania' },
  { id: 'CZ', value: 'Catanzaro' },
  { id: 'CH', value: 'Chieti' },
  { id: 'CO', value: 'Como' },
  { id: 'CS', value: 'Cosenza' },
  { id: 'CR', value: 'Cremona' },
  { id: 'KR', value: 'Crotone' },
  { id: 'CN', value: 'Cuneo' },
  { id: 'EN', value: 'Enna' },
  { id: 'FM', value: 'Fermo' },
  { id: 'FE', value: 'Ferrara' },
  { id: 'FI', value: 'Firenze' },
  { id: 'FG', value: 'Foggia' },
  { id: 'FC', value: 'Forlì-Cesena' },
  { id: 'FR', value: 'Frosinone' },
  { id: 'GE', value: 'Genova' },
  { id: 'GO', value: 'Gorizia' },
  { id: 'GR', value: 'Grosseto' },
  { id: 'IM', value: 'Imperia' },
  { id: 'IS', value: 'Isernia' },
  { id: 'SP', value: 'La Spezia' },
  { id: 'AQ', value: "L'Aquila" },
  { id: 'LT', value: 'Latina' },
  { id: 'LE', value: 'Lecce' },
  { id: 'LC', value: 'Lecco' },
  { id: 'LI', value: 'Livorno' },
  { id: 'LO', value: 'Lodi' },
  { id: 'LU', value: 'Lucca' },
  { id: 'MC', value: 'Macerata' },
  { id: 'MN', value: 'Mantova' },
  { id: 'MS', value: 'Massa-Carrara' },
  { id: 'MT', value: 'Matera' },
  { id: 'ME', value: 'Messina' },
  { id: 'MI', value: 'Milano' },
  { id: 'MO', value: 'Modena' },
  { id: 'MB', value: 'Monza e della Brianza' },
  { id: 'NA', value: 'Napoli' },
  { id: 'NO', value: 'Novara' },
  { id: 'NU', value: 'Nuoro' },
  { id: 'OT', value: 'Olbia-Tempio' },
  { id: 'OR', value: 'Oristano' },
  { id: 'PD', value: 'Padova' },
  { id: 'PA', value: 'Palermo' },
  { id: 'PR', value: 'Parma' },
  { id: 'PV', value: 'Pavia' },
  { id: 'PG', value: 'Perugia' },
  { id: 'PU', value: 'Pesaro e Urbino' },
  { id: 'PE', value: 'Pescara' },
  { id: 'PC', value: 'Piacenza' },
  { id: 'PI', value: 'Pisa' },
  { id: 'PT', value: 'Pistoia' },
  { id: 'PN', value: 'Pordenone' },
  { id: 'PZ', value: 'Potenza' },
  { id: 'PO', value: 'Prato' },
  { id: 'RG', value: 'Ragusa' },
  { id: 'RA', value: 'Ravenna' },
  { id: 'RC', value: 'Reggio Calabria' },
  { id: 'RE', value: 'Reggio Emilia' },
  { id: 'RI', value: 'Rieti' },
  { id: 'RN', value: 'Rimini' },
  { id: 'RM', value: 'Roma' },
  { id: 'RO', value: 'Rovigo' },
  { id: 'SA', value: 'Salerno' },
  { id: 'VS', value: 'Medio Campidano' },
  { id: 'SS', value: 'Sassari' },
  { id: 'SV', value: 'Savona' },
  { id: 'SI', value: 'Siena' },
  { id: 'SR', value: 'Siracusa' },
  { id: 'SO', value: 'Sondrio' },
  { id: 'TA', value: 'Taranto' },
  { id: 'TE', value: 'Teramo' },
  { id: 'TR', value: 'Terni' },
  { id: 'TO', value: 'Torino' },
  { id: 'OG', value: 'Ogliastra' },
  { id: 'TP', value: 'Trapani' },
  { id: 'TN', value: 'Trento' },
  { id: 'TV', value: 'Treviso' },
  { id: 'TS', value: 'Trieste' },
  { id: 'UD', value: 'Udine' },
  { id: 'VA', value: 'Varese' },
  { id: 'VE', value: 'Venezia' },
  { id: 'VB', value: 'Verbano-Cusio-Ossola' },
  { id: 'VC', value: 'Vercelli' },
  { id: 'VR', value: 'Verona' },
  { id: 'VV', value: 'Vibo Valentia' },
  { id: 'VI', value: 'Vicenza' },
  { id: 'VT', value: 'Viterbo' }
];

export const countryUserDetailList = [
  { id: 'IT', value: 'Italia' },
  { id: 'AF', value: 'Afghanistan' },
  { id: 'AL', value: 'Albania' },
  { id: 'DZ', value: 'Algeria' },
  { id: 'AD', value: 'Andorra' },
  { id: 'AO', value: 'Angola' },
  { id: 'AI', value: 'Anguilla' },
  { id: 'AQ', value: 'Antartide' },
  { id: 'AG', value: 'Antigua e Barbuda' },
  { id: 'AN', value: 'Antille Olandesi' },
  { id: 'SA', value: 'Arabia Saudita' },
  { id: 'AR', value: 'Argentina' },
  { id: 'AM', value: 'Armenia' },
  { id: 'AW', value: 'Aruba' },
  { id: 'AU', value: 'Australia' },
  { id: 'AT', value: 'Austria' },
  { id: 'AZ', value: 'Azerbaigian' },
  { id: 'BS', value: 'Bahamas' },
  { id: 'BH', value: 'Bahrain' },
  { id: 'BD', value: 'Bangladesh' },
  { id: 'BB', value: 'Barbados' },
  { id: 'BE', value: 'Belgio' },
  { id: 'BZ', value: 'Belize' },
  { id: 'BJ', value: 'Benin' },
  { id: 'BM', value: 'Bermuda' },
  { id: 'BT', value: 'Bhutan' },
  { id: 'BY', value: 'Bielorussia' },
  { id: 'BO', value: 'Bolivia' },
  { id: 'BQ', value: 'Bonaire, Sint Eustatius e Saba' },
  { id: 'BA', value: 'Bosnia-Erzegovina' },
  { id: 'BW', value: 'Botswana' },
  { id: 'BR', value: 'Brasile' },
  { id: 'BN', value: 'Brunei' },
  { id: 'BG', value: 'Bulgaria' },
  { id: 'BF', value: 'Burkina Faso' },
  { id: 'BI', value: 'Burundi' },
  { id: 'KH', value: 'Cambogia' },
  { id: 'CM', value: 'Camerun' },
  { id: 'CA', value: 'Canada' },
  { id: 'CV', value: 'Capo Verde' },
  { id: 'TD', value: 'Ciad' },
  { id: 'CL', value: 'Cile' },
  { id: 'CN', value: 'Cina' },
  { id: 'CY', value: 'Cipro' },
  { id: 'VA', value: 'Città del Vaticano' },
  { id: 'CO', value: 'Colombia' },
  { id: 'KM', value: 'Comore' },
  { id: 'CG', value: 'Congo' },
  { id: 'KP', value: 'Corea del Nord' },
  { id: 'KR', value: 'Corea del Sud' },
  { id: 'CR', value: 'Costa Rica' },
  { id: 'CI', value: "Costa d'Avorio" },
  { id: 'HR', value: 'Croazia' },
  { id: 'CU', value: 'Cuba' },
  { id: 'CW', value: 'Curaçao' },
  { id: 'DK', value: 'Danimarca' },
  { id: 'DM', value: 'Dominica' },
  { id: 'EC', value: 'Ecuador' },
  { id: 'EG', value: 'Egitto' },
  { id: 'SV', value: 'El Salvador' },
  { id: 'AE', value: 'Emirati Arabi Uniti' },
  { id: 'ER', value: 'Eritrea' },
  { id: 'EE', value: 'Estonia' },
  { id: 'ET', value: 'Etiopia' },
  { id: 'FJ', value: 'Figi' },
  { id: 'PH', value: 'Filippine' },
  { id: 'FI', value: 'Finlandia' },
  { id: 'FR', value: 'Francia' },
  { id: 'GA', value: 'Gabon' },
  { id: 'GM', value: 'Gambia' },
  { id: 'GE', value: 'Georgia' },
  { id: 'GS', value: 'Georgia del sud e isole Sandwich meridionali' },
  { id: 'DE', value: 'Germania' },
  { id: 'GH', value: 'Ghana' },
  { id: 'JM', value: 'Giamaica' },
  { id: 'JP', value: 'Giappone' },
  { id: 'GI', value: 'Gibilterra' },
  { id: 'DJ', value: 'Gibuti' },
  { id: 'JO', value: 'Giordania' },
  { id: 'GR', value: 'Grecia' },
  { id: 'GD', value: 'Grenada' },
  { id: 'GL', value: 'Groenlandia' },
  { id: 'GP', value: 'Guadalupa' },
  { id: 'GU', value: 'Guam' },
  { id: 'GT', value: 'Guatemala' },
  { id: 'GF', value: 'Guayana Francese' },
  { id: 'GG', value: 'Guernsey' },
  { id: 'GN', value: 'Guinea' },
  { id: 'GW', value: 'Guinea Bissau' },
  { id: 'GQ', value: 'Guinea Equatoriale' },
  { id: 'GY', value: 'Guyana' },
  { id: 'HT', value: 'Haiti' },
  { id: 'HN', value: 'Honduras' },
  { id: 'HK', value: 'Hong Kong' },
  { id: 'IN', value: 'India' },
  { id: 'ID', value: 'Indonesia' },
  { id: 'IR', value: 'Iran' },
  { id: 'IQ', value: 'Iraq' },
  { id: 'IE', value: 'Irlanda' },
  { id: 'IS', value: 'Islanda' },
  { id: 'NF', value: 'Isola Norfolk' },
  { id: 'BV', value: 'Isola di Bouvet' },
  { id: 'IM', value: 'Isola di Man' },
  { id: 'CX', value: 'Isola di Natale' },
  { id: 'AX', value: 'Isole Aland' },
  { id: 'KY', value: 'Isole Cayman' },
  { id: 'CC', value: 'Isole Cocos' },
  { id: 'CK', value: 'Isole Cook' },
  { id: 'FK', value: 'Isole Falkland' },
  { id: 'FO', value: 'Isole Færoer' },
  { id: 'HM', value: 'Isole Heard e McDonald' },
  { id: 'MP', value: 'Isole Marianne settentrionali' },
  { id: 'MH', value: 'Isole Marshall' },
  { id: 'SB', value: 'Isole Solomon' },
  { id: 'TC', value: 'Isole Turks e Caicos' },
  { id: 'VG', value: 'Isole Vergini (GB)' },
  { id: 'VI', value: 'Isole Vergini (USA)' },
  { id: 'UM', value: 'Isole minori lontane degli Stati Uniti' },
  { id: 'IL', value: 'Israele' },
  { id: 'IT', value: 'Italia' },
  { id: 'JE', value: 'Jersey' },
  { id: 'KZ', value: 'Kazakhstan' },
  { id: 'KE', value: 'Kenya' },
  { id: 'KG', value: 'Kirghizistan' },
  { id: 'KI', value: 'Kiribati' },
  { id: 'KW', value: 'Kuwait' },
  { id: 'LA', value: 'Laos' },
  { id: 'LS', value: 'Lesotho' },
  { id: 'LV', value: 'Lettonia' },
  { id: 'LB', value: 'Libano' },
  { id: 'LR', value: 'Liberia' },
  { id: 'LY', value: 'Libia' },
  { id: 'LI', value: 'Liechtenstein' },
  { id: 'LT', value: 'Lituania' },
  { id: 'LU', value: 'Lussemburgo' },
  { id: 'MO', value: 'Macao' },
  { id: 'MK', value: 'Macedonia' },
  { id: 'MG', value: 'Madagascar' },
  { id: 'MW', value: 'Malawi' },
  { id: 'MY', value: 'Malaysia' },
  { id: 'MV', value: 'Maldive' },
  { id: 'ML', value: 'Mali' },
  { id: 'MT', value: 'Malta' },
  { id: 'MA', value: 'Marocco' },
  { id: 'MQ', value: 'Martinica' },
  { id: 'MR', value: 'Mauritania' },
  { id: 'MU', value: 'Maurizio' },
  { id: 'YT', value: 'Mayotta' },
  { id: 'MX', value: 'Messico' },
  { id: 'FM', value: 'Micronesia' },
  { id: 'MD', value: 'Moldavia' },
  { id: 'MC', value: 'Monaco' },
  { id: 'MN', value: 'Mongolia' },
  { id: 'ME', value: 'Montenegro' },
  { id: 'MS', value: 'Montserrat' },
  { id: 'MZ', value: 'Mozambico' },
  { id: 'MM', value: 'Myanmar' },
  { id: 'NA', value: 'Namibia' },
  { id: 'NR', value: 'Nauru' },
  { id: 'NP', value: 'Nepal' },
  { id: 'NI', value: 'Nicaragua' },
  { id: 'NE', value: 'Niger' },
  { id: 'NG', value: 'Nigeria' },
  { id: 'NU', value: 'Niue' },
  { id: 'NO', value: 'Norvegia' },
  { id: 'NC', value: 'Nuova Caledonia' },
  { id: 'NZ', value: 'Nuova Zelanda' },
  { id: 'OM', value: 'Oman' },
  { id: 'NL', value: 'Paesi Bassi' },
  { id: 'PK', value: 'Pakistan' },
  { id: 'PW', value: 'Palau' },
  { id: 'PS', value: 'Palestina' },
  { id: 'PA', value: 'Panama' },
  { id: 'PG', value: 'Papua Nuova Guinea' },
  { id: 'PY', value: 'Paraguay' },
  { id: 'PE', value: 'Perù' },
  { id: 'PN', value: 'Pitcairn' },
  { id: 'PF', value: 'Polinesia Francese' },
  { id: 'PL', value: 'Polonia' },
  { id: 'PT', value: 'Portogallo' },
  { id: 'PR', value: 'Puerto Rico' },
  { id: 'QA', value: 'Qatar' },
  { id: 'GB', value: 'Regno Unito' },
  { id: 'CZ', value: 'Repubblica Ceca' },
  { id: 'CF', value: 'Repubblica Centrafricana' },
  { id: 'DO', value: 'Repubblica Dominicana' },
  { id: 'CD', value: 'Repubblica democratica del Congo' },
  { id: 'RE', value: 'Reunion' },
  { id: 'RO', value: 'Romania' },
  { id: 'RW', value: 'Ruanda' },
  { id: 'RU', value: 'Russia' },
  { id: 'EH', value: 'Sahara Occidentale' },
  { id: 'BL', value: 'Saint Barthélemy' },
  { id: 'KN', value: 'Saint Kitts e Nevis' },
  { id: 'MF', value: 'Saint Martin' },
  { id: 'PM', value: 'Saint Pierre e Miquelon' },
  { id: 'VC', value: 'Saint Vincent e Grenadine' },
  { id: 'LC', value: 'Sainte Lucia' },
  { id: 'WS', value: 'Samoa' },
  { id: 'AS', value: 'Samoa americane' },
  { id: 'SM', value: 'San Marino' },
  { id: 'SH', value: "Sant'Elena" },
  { id: 'SN', value: 'Senegal' },
  { id: 'RS', value: 'Serbia' },
  { id: 'SC', value: 'Seychelles' },
  { id: 'SL', value: 'Sierra Leone' },
  { id: 'SG', value: 'Singapore' },
  { id: 'SX', value: 'Sint Maarten (parte olandese)' },
  { id: 'SY', value: 'Siria' },
  { id: 'SK', value: 'Slovacchia' },
  { id: 'SI', value: 'Slovenia' },
  { id: 'SO', value: 'Somalia' },
  { id: 'ES', value: 'Spagna' },
  { id: 'LK', value: 'Sri Lanka' },
  { id: 'US', value: 'Stati Uniti' },
  { id: 'ZA', value: 'Sudafrica' },
  { id: 'SD', value: 'Sudan' },
  { id: 'SS', value: 'Sudan del Sud' },
  { id: 'SR', value: 'Suriname' },
  { id: 'SJ', value: 'Svalbard e Jan Mayen' },
  { id: 'SE', value: 'Svezia' },
  { id: 'CH', value: 'Svizzera' },
  { id: 'SZ', value: 'Swaziland' },
  { id: 'ST', value: 'São Tomé e Principe' },
  { id: 'TJ', value: 'Tagikistan' },
  { id: 'TW', value: 'Taiwan' },
  { id: 'TZ', value: 'Tanzania' },
  { id: 'TF', value: "Territori Francesi d'Oltremare" },
  { id: 'IO', value: "Territorio britannico dell'Oceano Indiano" },
  { id: 'TH', value: 'Thailandia' },
  { id: 'TL', value: 'Timor Leste' },
  { id: 'TG', value: 'Togo' },
  { id: 'TK', value: 'Tokelau' },
  { id: 'TO', value: 'Tonga' },
  { id: 'TT', value: 'Trinidad e Tobago' },
  { id: 'TN', value: 'Tunisia' },
  { id: 'TR', value: 'Turchia' },
  { id: 'TM', value: 'Turkmenistan' },
  { id: 'TV', value: 'Tuvalu' },
  { id: 'UA', value: 'Ucraina' },
  { id: 'UG', value: 'Uganda' },
  { id: 'HU', value: 'Ungheria' },
  { id: 'UY', value: 'Uruguay' },
  { id: 'UZ', value: 'Uzbekistan' },
  { id: 'VU', value: 'Vanuatu' },
  { id: 'VE', value: 'Venezuela' },
  { id: 'VN', value: 'Vietnam' },
  { id: 'WF', value: 'Wallis e Futuna' },
  { id: 'YE', value: 'Yemen' },
  { id: 'ZM', value: 'Zambia' },
  { id: 'ZW', value: 'Zimbabwe' }
];

export const menuSections = [
  {
    position: 0,
    label: 'MENU.MENU_SECTION_APPETIZER'
  },
  {
    position: 1,
    label: 'MENU.MENU_SECTION_STARTER'
  },
  {
    position: 2,
    label: 'MENU.MENU_SECTION_MAINCOURSE'
  },
  {
    position: 3,
    label: 'MENU.MENU_SECTION_SIDEDASH'
  },
  {
    position: 4,
    label: 'MENU.MENU_SECTION_DESSERT'
  },
  {
    position: 5,
    label: 'MENU.MENU_SECTION_OTHER'
  }
];

export const changePasswordUrl = `${environment.keycloack.baseUrl}/realms/${environment.keycloack.realm}/account/password`;

export const parameterDevice: DeviceParamNBKExtended[] = [
  { id: 1, name: 'Parametro 1', min: 10, max: 100, value: 0 },
  { id: 2, name: 'Parametro 2', min: 10, max: 100, value: 0 },
  { id: 3, name: 'Parametro 3', min: 10, max: 100, value: 0 },
  { id: 4, name: 'Parametro 4', min: 10, max: 100, value: 0 },
  { id: 5, name: 'Parametro 5', min: 10, max: 100, value: 0 },
  { id: 6, name: 'Parametro 6', min: 10, max: 100, value: 0 },
  { id: 7, name: 'Parametro 7', min: 10, max: 100, value: 0 },
  { id: 8, name: 'Parametro 8', min: 10, max: 100, value: 0 },
  { id: 9, name: 'Parametro 9', min: 10, max: 100, value: 0 },
  { id: 10, name: 'Parametro 10', min: 10, max: 100, value: 0 },
  { id: 11, name: 'Parametro 11', min: 10, max: 100, value: 0 },
  { id: 13, name: 'Parametro 13', min: 10, max: 100, value: 0 },
  { id: 14, name: 'Parametro 14', min: 10, max: 100, value: 0 },
  { id: 15, name: 'Parametro 15', min: 10, max: 100, value: 0 }
];

export const languagesList = [
  { lang: 'ar', label: 'عربيه', flag: '🇦🇪' },
  { lang: 'bg', label: 'БЪЛГАРСКИ', flag: '🇧🇬' },
  { lang: 'cs', label: 'ČEŠTINA', flag: '🇨🇿' },
  { lang: 'de', label: 'DEUTSCH', flag: '🇩🇪' },
  { lang: 'da', label: 'DANSK', flag: '🇩🇰' },
  { lang: 'el', label: 'ΕΛΛΗΝΙΚΆ', flag: '🇬🇷' },
  { lang: 'en', label: 'ENGLISH', flag: '🇬🇧' },
  { lang: 'es', label: 'ESPAÑOL', flag: '🇪🇸' },
  { lang: 'fi', label: 'SUOMI', flag: '🇫🇮' },
  { lang: 'fr', label: 'FRANÇAIS', flag: '🇫🇷' },
  { lang: 'hr', label: 'HRVATSKI', flag: '🇭🇷' },
  { lang: 'hu', label: 'MAGYAR', flag: '🇭🇺' },
  { lang: 'it', label: 'ITALIANO', flag: '🇮🇹' },
  { lang: 'ko', label: '코리아나', flag: '🇰🇷' },
  { lang: 'lv', label: 'LATVIEŠU VALODA', flag: '🇱🇻' },
  { lang: 'nl', label: 'NEDERLANDS', flag: '🇳🇱' },
  { lang: 'nn', label: 'NORSK', flag: '🇳🇴' },
  { lang: 'pl', label: 'POLSKI', flag: '🇵🇱' },
  { lang: 'pt', label: 'PORTUGUÊS', flag: '🇵🇹' },
  { lang: 'ro', label: 'ROMÂNĂ', flag: '🇷🇴' },
  { lang: 'ru', label: 'русский', flag: '🇷🇺' },
  { lang: 'sl', label: 'SLOVENŠČINA', flag: '🇸🇮' },
  { lang: 'sr', label: 'Српски', flag: '🇷🇸' },
  { lang: 'sv', label: 'SVENSKA', flag: '🇸🇪' },
  { lang: 'tr', label: 'Türk', flag: '🇹🇷' }
];

export const KEY_LANGUAGE_STORAGE = 'lainox_nabook_plus_language';

export const getUserDisplayMode = (kcUser: UserKeycloakDtoNBK) => {
  return kcUser.multiDisplay ? DisplayListBoDtoNBK.ModeEnum.MultiDisplay : DisplayListBoDtoNBK.ModeEnum.SingleDisplay
}

export const getUrlRedirectPopupDevice = (
  baseUrl: string,
  realm: string,
  origin: string
) => {
  const state = (Math.random() + 1).toString(36).substring(2);
  return `${baseUrl}/realms/${realm}/protocol/openid-connect/auth?response_type=code&client_id=iot&redirect_uri=${origin}/cloud/api/bo/iot_callback&scope=openid%20offline_access&state=${state}`;
};

export const getCookingModeList = ( deviceModel: DeviceModelEnum ): SelectableCookingMode[] => {
  return deviceConfiguration[deviceModel].map((deviceConf) => ({
    id: deviceConf.coockingMode as CookingModeEnum ,
    name: 'COOKING_MODE.' + deviceConf.coockingMode
  }));
}

export const getDeviceModelList = () => Object.values(RecipeBoDtoNBK.DeviceModelEnum).map(
  (key) => ({
    id: key,
    name: 'DEVICE_MODEL.' + key
  })
);

export const upperCaseTooltip = (name?: string) => {
  return name ? name.toUpperCase() : '';
}

export const  disableTooltip = (el: HTMLSpanElement) => {
  return el.scrollWidth <= el.clientWidth && el.scrollHeight <= el.clientHeight;
}

export const humanRedableMillis = (millis: number, ts: TranslateService) => {
  var time: { [key:string]: number } = { 'GLOBAL.TIME.YEAR': 31536000, 'GLOBAL.TIME.DAY': 86400, 
    'GLOBAL.TIME.HOUR': 3600, 'GLOBAL.TIME.MINUTE': 60, 'GLOBAL.TIME.SECOND': 1 };
  var res: any[] = [];

  if (millis === 0) return '--';

  var seconds = Math.round(millis/1000);
  
  for (var key in time) {
    if (seconds >= time[key]) {
      var val: any = Math.floor(seconds/time[key]);
      res.push(val += ' ' + ts.instant(val > 1 ? key + 'S' : key) );
      seconds = seconds % time[key];
    }
  }
 
  return res.length > 1 ? res.join(', ') : res[0] as string;
}

export const checkScreenSize = () => {
  switch (true) {
    case window.innerWidth < 768:
      return ScreenSize.Small;
    case window.innerWidth >= 768 && window.innerWidth < 992:
        return ScreenSize.Medium;
    default:
      return ScreenSize.Large
  }
}

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getEntries = (obj: { [key: string]: any }) => {
  return Object.entries(obj);
}

