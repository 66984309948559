/**
 * Nabook Plus Cloud Bo
 * Web API for Bo
 *
 * The version of the OpenAPI document: 2.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { AlarmsListDtoNBK } from '../model/models';
import { InfluxEventRecapNBK } from '../model/models';
import { InfluxEventsDetailDtoNBK } from '../model/models';
import { InfluxLastCountersDtoNBK } from '../model/models';
import { InfluxLastStateDtoNBK } from '../model/models';
import { InfluxRecipesCounterDtoNBK } from '../model/models';
import { InfluxTemperatureSeriesDtoNBK } from '../model/models';
import { InfluxTimingDtoNBK } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {
  protected basePath = 'http://localhost/cloud/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Generate a file with full properties for a device in the given range
   * @param stop
   * @param device
   * @param start
   * @param acceptLanguage
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadFullFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public downloadFullFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public downloadFullFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public downloadFullFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling downloadFullFileData.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'Blob' | 'File' = 'Blob';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'Blob';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/bo/telemetry/download-extended`,
      body,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Generate a file with haccp properties for a device in the given range
   * @param stop
   * @param device
   * @param start
   * @param acceptLanguage
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public downloadHaccpFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any>;
  public downloadHaccpFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpResponse<any>>;
  public downloadHaccpFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<HttpEvent<any>>;
  public downloadHaccpFileData(
    stop: string,
    device?: string,
    start?: string,
    acceptLanguage?: string,
    body?: { [key: string]: string },
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined }
  ): Observable<any> {
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling downloadHaccpFileData.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json; charset=utf-8'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'Blob' | 'File' = 'Blob';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'Blob';
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/bo/telemetry/download-haccp`,
      body,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device alarms list for the given date range.
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAlarmsList(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<AlarmsListDtoNBK>;
  public getAlarmsList(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<AlarmsListDtoNBK>>;
  public getAlarmsList(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<AlarmsListDtoNBK>>;
  public getAlarmsList(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getAlarmsList.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getAlarmsList.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getAlarmsList.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<AlarmsListDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/alarms`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device events list for a single day
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEventsListDeatail(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxEventsDetailDtoNBK>;
  public getEventsListDeatail(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxEventsDetailDtoNBK>>;
  public getEventsListDeatail(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxEventsDetailDtoNBK>>;
  public getEventsListDeatail(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getEventsListDeatail.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getEventsListDeatail.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getEventsListDeatail.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxEventsDetailDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/events-detail`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device events list recap
   * @param device
   * @param timezone must be like Europe/Rome
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getEventsListRecap(
    device: string,
    timezone: string,
    start: string,
    stop: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<Array<InfluxEventRecapNBK>>;
  public getEventsListRecap(
    device: string,
    timezone: string,
    start: string,
    stop: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<Array<InfluxEventRecapNBK>>>;
  public getEventsListRecap(
    device: string,
    timezone: string,
    start: string,
    stop: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<Array<InfluxEventRecapNBK>>>;
  public getEventsListRecap(
    device: string,
    timezone: string,
    start: string,
    stop: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getEventsListRecap.'
      );
    }
    if (timezone === null || timezone === undefined) {
      throw new Error(
        'Required parameter timezone was null or undefined when calling getEventsListRecap.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getEventsListRecap.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getEventsListRecap.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (timezone !== undefined && timezone !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>timezone,
        'timezone'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<Array<InfluxEventRecapNBK>>(
      `${this.configuration.basePath}/bo/telemetry/events`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device idle times for the given range. Data are in ms.
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getIdleTimes(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxTimingDtoNBK>;
  public getIdleTimes(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxTimingDtoNBK>>;
  public getIdleTimes(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxTimingDtoNBK>>;
  public getIdleTimes(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getIdleTimes.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getIdleTimes.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getIdleTimes.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxTimingDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/idle-times`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device last counters
   * @param device
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLastCounters(
    device: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxLastCountersDtoNBK>;
  public getLastCounters(
    device: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxLastCountersDtoNBK>>;
  public getLastCounters(
    device: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxLastCountersDtoNBK>>;
  public getLastCounters(
    device: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getLastCounters.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxLastCountersDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/counters`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device last status
   * @param device
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLastStatus(
    device: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxLastStateDtoNBK>;
  public getLastStatus(
    device: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxLastStateDtoNBK>>;
  public getLastStatus(
    device: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxLastStateDtoNBK>>;
  public getLastStatus(
    device: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getLastStatus.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxLastStateDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/status`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device m1 and m2 times for the given range. Data are in ms.
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getM1M2Times(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxTimingDtoNBK>;
  public getM1M2Times(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxTimingDtoNBK>>;
  public getM1M2Times(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxTimingDtoNBK>>;
  public getM1M2Times(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getM1M2Times.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getM1M2Times.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getM1M2Times.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxTimingDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/m1m2-times`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device modes of uses for the given range. Data are in ms.
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getModesOfUse(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxTimingDtoNBK>;
  public getModesOfUse(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxTimingDtoNBK>>;
  public getModesOfUse(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxTimingDtoNBK>>;
  public getModesOfUse(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getModesOfUse.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getModesOfUse.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getModesOfUse.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxTimingDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/modes-of-use`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device recipes counter for the given range
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecipesCounter(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxRecipesCounterDtoNBK>;
  public getRecipesCounter(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxRecipesCounterDtoNBK>>;
  public getRecipesCounter(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxRecipesCounterDtoNBK>>;
  public getRecipesCounter(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getRecipesCounter.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getRecipesCounter.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getRecipesCounter.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxRecipesCounterDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/recipes-counter`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get temperature values between the given intervall
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTemperaturePoints(
    device: string,
    start: string,
    stop?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxTemperatureSeriesDtoNBK>;
  public getTemperaturePoints(
    device: string,
    start: string,
    stop?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxTemperatureSeriesDtoNBK>>;
  public getTemperaturePoints(
    device: string,
    start: string,
    stop?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxTemperatureSeriesDtoNBK>>;
  public getTemperaturePoints(
    device: string,
    start: string,
    stop?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getTemperaturePoints.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getTemperaturePoints.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxTemperatureSeriesDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/temperature`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device turned off time for the given range. Data are in ms.
   * @param device
   * @param start must be like 2022-04-19T13:40:28.422Z
   * @param stop must be like 2022-04-19T13:40:28.422Z
   * @param acceptLanguage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTurnedOnTime(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<InfluxTimingDtoNBK>;
  public getTurnedOnTime(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpResponse<InfluxTimingDtoNBK>>;
  public getTurnedOnTime(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<HttpEvent<InfluxTimingDtoNBK>>;
  public getTurnedOnTime(
    device: string,
    start: string,
    stop: string,
    acceptLanguage?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json; charset&#x3D;utf-8' }
  ): Observable<any> {
    if (device === null || device === undefined) {
      throw new Error(
        'Required parameter device was null or undefined when calling getTurnedOnTime.'
      );
    }
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling getTurnedOnTime.'
      );
    }
    if (stop === null || stop === undefined) {
      throw new Error(
        'Required parameter stop was null or undefined when calling getTurnedOnTime.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (device !== undefined && device !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>device,
        'device'
      );
    }
    if (start !== undefined && start !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>start,
        'start'
      );
    }
    if (stop !== undefined && stop !== null) {
      queryParameters = this.addToHttpParams(
        queryParameters,
        <any>stop,
        'stop'
      );
    }

    let headers = this.defaultHeaders;
    if (acceptLanguage !== undefined && acceptLanguage !== null) {
      headers = headers.set('Accept-Language', String(acceptLanguage));
    }

    let credential: string | undefined;
    // authentication (nabookstag) required
    credential = this.configuration.lookupCredential('nabookstag');
    if (credential) {
      headers = headers.set('Authorization', 'Bearer ' + credential);
    }

    let httpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json; charset=utf-8'];
      httpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType_: 'text' | 'json' = 'json';
    if (
      httpHeaderAcceptSelected &&
      httpHeaderAcceptSelected.startsWith('text')
    ) {
      responseType_ = 'text';
    }

    return this.httpClient.get<InfluxTimingDtoNBK>(
      `${this.configuration.basePath}/bo/telemetry/turned-on-time`,
      {
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
